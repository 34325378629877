import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SYSTEM_ENGINE_DOCS_URL } from "utils/constants";

import { useIsFirstUser } from "hooks/useIsFirstUser";
import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { SqlRbacAction } from "services/rbac/action";

import ChevronDown from "assets/icons/ChevronDown.svg?react";
import DatabaseIcon from "assets/icons/DatabaseSelectorIcon.svg?react";
import EngineIcon from "assets/icons/EngineSelectorIcon.svg?react";

import { DocumentStatus } from "pages/DevelopWorkspace/Editor/Document/Document";
import DocumentActionsPanel from "pages/DevelopWorkspace/Editor/Document/DocumentContextSelector/DocumentActionsPanel/DocumentActionsPanel";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  ExecutionType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { useAccessManager } from "components/App/accessManager";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { EngineStatusToggle } from "components/EngineStatusToggle/EngineStatusToggle";
import { Search } from "components/LeftSidebar/Search";
import Tooltip from "components/Tooltip";

import { NONE } from "./types";

import styles from "./DocumentContextSelector.module.scss";

interface DocumentContextSelectorProps {
  document: WorkspaceDocument;
  documentStatus: DocumentStatus;
  executionType: ExecutionType;
  onExecutionTypeChange: (type: ExecutionType) => void;
}

const DocumentContextSelector = (props: DocumentContextSelectorProps) => {
  const { document, executionType, onExecutionTypeChange, documentStatus } =
    props;
  const isFirstUser = useIsFirstUser();
  const {
    data: engines,
    isLoading: isEnginesLoading,
    error: enginesError,
  } = useWorkspaceEngines({
    includeSystemEngine: true,
    isFirstUser,
  });
  const { isAllowedTo } = useAccessManager();

  const { t } = useTranslation();
  const databaseSelectorRef = useRef<HTMLDivElement>(null);
  const [databaseSelectorOpen, setDatabaseSelectorOpen] = useState(false);
  const [searchDatabase, setSearchDatabase] = useState("");

  const searchEngineRef = useRef<HTMLDivElement>(null);
  const [engineSelectorOpen, setEngineSelectorOpen] = useState(false);
  const [searchEngine, setSearchEngine] = useState("");
  const databases = useDatabasesNames({
    isFirstUser,
    showSystemDatabases: true,
  });

  const {
    actions: { changeDocumentContext },
  } = useDocuments();

  if (isEnginesLoading || !databases) {
    return <div>Loading...</div>;
  }

  if (enginesError) {
    return <div>Error loading engines</div>;
  }

  if (!engines || !databases) {
    return <div>No engines or databases</div>;
  }

  const sortedEngines = [...engines].sort((a, b) => {
    if (a.engineName === "system") return -1;
    if (b.engineName === "system") return 1;

    return a.engineName.localeCompare(b.engineName);
  });

  const foundDatabases = databases.filter(database => {
    return database.catalogName
      .toLowerCase()
      .includes(searchDatabase.toLowerCase());
  });

  const foundEngines = sortedEngines.filter(engine => {
    return engine.engineName.toLowerCase().includes(searchEngine.toLowerCase());
  });

  const engine = engines.find(
    engine => engine.engineName === document.context.engineName
  );

  const isDisabled =
    documentStatus.isUnknown ||
    documentStatus.isRestoring ||
    documentStatus.isRunning;

  const dbItems = [
    ...foundDatabases.map(database => (
      <ContextMenuItem
        key={database.catalogName}
        checked={database.catalogName === document.context.databaseName}
        onClick={() => {
          changeDocumentContext(document.id, {
            databaseName: database.catalogName,
          });
          setDatabaseSelectorOpen(false);
        }}
        text={database.catalogName}
        testId={`context-db-item-${database.catalogName}`}
      />
    )),
  ];

  if (!searchDatabase) {
    dbItems.unshift(
      <ContextMenuItem
        text="None"
        key="none"
        testId="no-database-item"
        onClick={() => {
          changeDocumentContext(document.id, {
            databaseName: NONE,
          });
          setDatabaseSelectorOpen(false);
        }}
      />
    );
  }

  const error = document.execution?.documentExecutionError;

  const getDatabaseNameLabel = (databaseName: string | Symbol) => {
    if (!databaseName || databaseName === NONE) {
      return "No database selected";
    }
    return databaseName as string;
  };

  return (
    <div className={styles.root}>
      <div className={styles.selectorsPanel}>
        <div
          className={cn(styles.selector, {
            [styles.active]: !!document.context.databaseName,
            [styles.disabled]: isDisabled,
          })}
          ref={databaseSelectorRef}
        >
          <div
            className={styles.button}
            onClick={() => {
              if (isDisabled) {
                return;
              }

              setSearchDatabase("");
              setDatabaseSelectorOpen(true);
            }}
            data-testid="database-context-selector"
          >
            <div className={styles.leftIcon}>
              <DatabaseIcon />
            </div>
            <div className={styles.text}>
              {getDatabaseNameLabel(document.context.databaseName)}
            </div>
            <div className={styles.rightIcon}>
              <ChevronDown />
            </div>
          </div>

          {databaseSelectorOpen && (
            <Popover
              open={databaseSelectorOpen}
              anchorEl={databaseSelectorRef.current}
              classes={{ paper: styles.paper }}
              onClose={() => {
                setDatabaseSelectorOpen(false);
              }}
              onClick={e => {
                e.stopPropagation();
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                {databases.length > 5 && (
                  <Search
                    testId="search-database-context"
                    onChange={setSearchDatabase}
                    value={searchDatabase}
                    autoFocus={true}
                    size="small"
                  />
                )}
              </div>
              <div className={styles.selectorItems}>
                {foundDatabases.length === 0 && searchDatabase ? (
                  <div className={styles.notFound}>
                    No matching databases found
                  </div>
                ) : (
                  <ContextMenu>
                    <ContextMenuItemsGroup
                      isGroup={true}
                      maxHeight={400}
                    >
                      {dbItems}
                    </ContextMenuItemsGroup>
                  </ContextMenu>
                )}
              </div>
            </Popover>
          )}
        </div>

        <div
          className={cn(styles.selector, {
            [styles.active]: document.context.engineName,
            [styles.disabled]: isDisabled,
            [styles.error]: !!error,
          })}
          ref={searchEngineRef}
        >
          <Tooltip
            classes={{ tooltip: styles.tooltip }}
            title={
              document.execution?.documentExecutionError ? (
                <div className={styles.tooltip__body}>
                  <span className={styles.tooltip__title}>
                    {document.execution.documentExecutionError.title}
                  </span>
                  <span className={styles.tooltip__description}>
                    {document.execution.documentExecutionError.description}
                  </span>
                </div>
              ) : null
            }
          >
            <div
              className={styles.button}
              onClick={() => {
                if (isDisabled) {
                  return;
                }

                setSearchEngine("");
                setEngineSelectorOpen(true);
              }}
              data-testid="engine-context-selector"
            >
              <div className={styles.leftIcon}>
                <EngineIcon />
              </div>
              <div className={styles.text}>{document.context.engineName}</div>

              {engine && (
                <EngineStatusToggle
                  error={error}
                  engine={engine}
                  inactive={true}
                  inline={true}
                  size="small"
                />
              )}
              <div className={styles.rightIcon}>
                <ChevronDown />
              </div>
            </div>
          </Tooltip>

          {engineSelectorOpen && (
            <Popover
              open={engineSelectorOpen}
              anchorEl={searchEngineRef.current}
              classes={{ paper: styles.paper }}
              onClose={() => {
                setEngineSelectorOpen(false);
              }}
              onClick={e => {
                e.stopPropagation();
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                {engines.length > 5 && (
                  <Search
                    testId="search-engine-context"
                    onChange={setSearchEngine}
                    value={searchEngine}
                    autoFocus={true}
                    size="small"
                  />
                )}
              </div>
              <div className={styles.selectorItems}>
                {foundEngines.length === 0 ? (
                  <div className={styles.notFound}>
                    No matching engines found
                  </div>
                ) : (
                  <ContextMenu>
                    <ContextMenuItemsGroup
                      isGroup={true}
                      maxHeight={400}
                    >
                      {foundEngines.map(engine => {
                        const isSystem = engine.engineName === "system";

                        const canOperateEngine = isAllowedTo(
                          "engine",
                          engine.engineName,
                          SqlRbacAction.OPERATE,
                          { owner: engine.engineOwner }
                        );
                        const markup = (
                          <ContextMenuItem
                            key={engine.engineName}
                            checked={
                              engine.engineName === document.context.engineName
                            }
                            availableForInteraction={canOperateEngine}
                            testId={`context-engine-item-${engine.engineName}`}
                            onClick={() => {
                              changeDocumentContext(document.id, {
                                engineName: engine.engineName,
                              });
                              setEngineSelectorOpen(false);
                            }}
                            checkedIconPlaceholder={true}
                            text={engine.engineName}
                            content={
                              <EngineStatusToggle
                                size="small"
                                inMenu={true}
                                inactive={isSystem}
                                engine={engine}
                              />
                            }
                            renderWithWrapper={
                              isSystem
                                ? element => {
                                    return (
                                      <Tooltip
                                        key={engine.engineName}
                                        onClick={e => e.stopPropagation()}
                                        title={
                                          <div className={styles.tooltip__body}>
                                            <span
                                              className={styles.tooltip__title}
                                            >
                                              {t(
                                                "workspace.context_selector.system_engine"
                                              )}
                                            </span>
                                            <a
                                              href={SYSTEM_ENGINE_DOCS_URL}
                                              target="_blank"
                                              className={styles.tooltip__link}
                                              rel="noreferrer"
                                            >
                                              {t(
                                                "workspace.context_selector.system_engine_documentation"
                                              )}
                                            </a>
                                          </div>
                                        }
                                        placement="right"
                                        classes={{ tooltip: styles.tooltip }}
                                      >
                                        <div>{element}</div>
                                      </Tooltip>
                                    );
                                  }
                                : undefined
                            }
                          />
                        );

                        return markup;
                      })}
                    </ContextMenuItemsGroup>
                  </ContextMenu>
                )}
              </div>
            </Popover>
          )}
        </div>
      </div>

      <DocumentActionsPanel
        executionType={executionType}
        onExecutionTypeChange={onExecutionTypeChange}
        document={document}
        documentStatus={documentStatus}
      />
    </div>
  );
};

export default DocumentContextSelector;
