import { Popover } from "@mui/material";
import classNames from "classnames";
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Trans } from "react-i18next";

import Alert from "assets/icons/Alert.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { CloseIcon, DropdownArrowDown } from "components/Icons";
import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import { Search } from "components/LeftSidebar/Search";
import Tooltip from "components/Tooltip/Tooltip";

import styles from "./styles.module.scss";

export const MIN_WIDTH = 240;

type SearchOptions = {
  searchPlaceholder: string;
  noResultsText?: string;
};

export const WithSearch = (props: {
  items?: React.ReactElement[];
  searchOptions?: SearchOptions;
  children: React.ReactElement;
  testId?: string;
}) => {
  const { children, items = [], searchOptions, testId } = props;
  const [search, setSearch] = useState("");

  if (!searchOptions) {
    return <div>{React.cloneElement(children, { items })}</div>;
  }

  const withDividers = items.filter(item => {
    if ((item?.type as any)?.role === "Divider") {
      return true;
    }
    if (item.props.skipFilter) {
      return true;
    }
    return item.props.value.toLowerCase().includes(search.toLowerCase());
  });

  const withoutSystemItems = withDividers.filter(item => {
    return (item?.type as any)?.role !== "Divider" && !item.props.skipFilter;
  });

  const { noResultsText, searchPlaceholder } = searchOptions;

  const defaultSearchString = (
    <div className={styles.noResults}>
      <span className={styles.noResults__message}>
        <Trans
          i18nKey={
            search ? "select.no_items_found_for" : "select.no_items_found"
          }
          values={{ search }}
          components={{ bold: <strong /> }}
        />
      </span>
    </div>
  );

  return (
    <div data-testid={testId}>
      <Search
        onChange={setSearch}
        value={search}
        autoFocus={true}
        size="small"
        placeholder={searchPlaceholder}
      />
      <div className={styles.selectorItems}>
        {withoutSystemItems.length ? (
          React.cloneElement(children, { items: withDividers })
        ) : (
          <div className={styles.notFound}>
            {noResultsText ? (
              <div className={styles.noResults}>
                <span className={styles.noResults__message}>
                  {noResultsText}
                </span>
              </div>
            ) : (
              defaultSearchString
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Select = (props: {
  handleSelect: (item: unknown, bulkItems: string[]) => void;
  items?: React.ReactElement[];
}) => {
  const { items = [], handleSelect } = props;

  const bulkItems = items
    .filter(item => item.props.bulkItem)
    .map(item => item.props.value);

  const menuElements = items.reduce<React.ReactElement[]>(
    (acc, item, index) => {
      if (
        (item?.type as any)?.role === "Divider" &&
        index === items.length - 1
      ) {
        return acc;
      }

      if ((item?.type as any)?.role === "Divider") {
        return [...acc, item];
      }

      const element = React.cloneElement(item, {
        onClick: handleSelect(item.props, bulkItems),
        handleSelect,
      });

      return [...acc, element];
    },
    []
  );

  return (
    <ContextMenu>
      <ContextMenuItemsGroup
        isGroup
        maxHeight={400}
      >
        {menuElements}
      </ContextMenuItemsGroup>
    </ContextMenu>
  );
};

type Props = {
  className?: string;
  wrapperClassName?: string;
  multiple?: boolean;
  disabled?: boolean;
  allowUncheck?: boolean;
  searchOptions?: SearchOptions;
  initialSelected?: string[];
  controlledValue?: string[];
  onSelect?: (items: string[]) => void;
  onClose?: () => void;
  renderValue?: (selectedItems: string[]) => React.ReactNode;
  renderMenu?: (handleClose: () => void) => React.ReactElement;
  children?: React.ReactElement[];
  error?: string;
  popoverProps?: any;
  testId?: string;
  noBorder?: boolean;
  showFilterApplied?: boolean;
  alertTooltip?: string;
  popoverRef?: RefObject<HTMLDivElement>;
  prefix?: React.ReactNode;
};

export const OutlinedSelect = (props: Props) => {
  const {
    className,
    multiple,
    allowUncheck = false,
    searchOptions,
    initialSelected = [],
    onSelect,
    renderValue,
    error,
    popoverProps,
    renderMenu,
    onClose,
    testId,
    wrapperClassName,
    controlledValue,
    noBorder,
    alertTooltip,
    popoverRef,
    prefix,
    showFilterApplied = false,
  } = props;
  const { menuElement, openMenu, closeMenu } = useMenu();
  const [focus, setFocus] = useState(false);
  const isDisabled = useContext(InputDisabledContext);
  const disabled = props.disabled || isDisabled;

  const selectRef = useRef<HTMLDivElement | null>(null);
  const menuMinWidth = useRef<number>(0);
  const [selectedItems, setSelectedItem] = useState<string[]>(initialSelected);

  useEffect(() => {
    if (controlledValue !== undefined) {
      setSelectedItem(controlledValue || []);
    }
  }, [controlledValue]);

  const setInitialWidth = () => {
    const { width = 0 } = selectRef.current?.getBoundingClientRect() || {};
    menuMinWidth.current = width;
  };

  const handleOpen = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    setInitialWidth();
    openMenu(event);
  };

  const handleKeyDown: React.KeyboardEventHandler = event => {
    if (disabled) {
      return;
    }
    if (event.key === "Enter" || event.key === "ArrowDown") {
      setInitialWidth();
      openMenu(event);
    }
  };

  const handleSelect = (item: unknown, bulkItems: string[]) => () => {
    const { value, disableClose, bulkItem } = item as {
      value: string;
      disableClose?: boolean;
      bulkItem?: boolean;
    };

    const getItems = () => {
      if (bulkItem) {
        return selectedItems.includes(value) ? [] : [value];
      }
      if (multiple) {
        const selected = selectedItems.includes(value)
          ? selectedItems.filter(i => i !== value)
          : [...selectedItems, value];
        return selected.filter(item => !bulkItems.includes(item));
      }
      if (allowUncheck) {
        return selectedItems.includes(value) ? [] : [value];
      }

      return [value];
    };

    const items = getItems();

    if (!controlledValue) {
      setSelectedItem(items);
    }
    onSelect && onSelect(items);
    !disableClose && !multiple && closeMenu();
  };

  const handleClearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!controlledValue) {
      setSelectedItem([]);
    }
    onSelect && onSelect([]);
  };

  const value = renderValue
    ? renderValue(selectedItems)
    : selectedItems.join(", ");

  const handleClose = () => {
    closeMenu();
    onClose && onClose();
  };

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  return (
    <div
      className={classNames(styles.wrapper, wrapperClassName, {
        [styles.error]: error,
        [styles.focus]: focus,
        [styles.active]: !!menuElement,
        [styles.noBorder]: noBorder,
      })}
      data-testid={testId}
    >
      <div
        className={classNames(styles.select, className, {
          [styles.disabled]: disabled,
          [styles.noBorder]: noBorder,
          [styles.filterApplied]: showFilterApplied && selectedItems.length > 0,
        })}
        onClick={handleOpen}
        onKeyDown={handleKeyDown}
        ref={selectRef}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid={`${testId}-inner-select`}
      >
        {prefix && <div className={styles.prefix}>{prefix}</div>}
        <div className={styles.value}>{value}</div>
        <div className={styles.right}>
          {alertTooltip && (
            <Tooltip title={alertTooltip}>
              <div className={styles.alertTooltip}>
                <Alert />
              </div>
            </Tooltip>
          )}
          {!!selectedItems.length && multiple && (
            <CloseIcon
              className={styles.clear}
              onClick={handleClearSelection}
            />
          )}
          <DropdownArrowDown className={styles.dropdownIcon} />
        </div>
      </div>
      {!!error && <span className={styles.error__label}>{error}</span>}
      {!!menuElement && (
        <Popover
          open
          anchorEl={menuElement}
          classes={{ paper: styles.paper }}
          onClose={handleClose}
          marginThreshold={8}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              minWidth: menuMinWidth.current,
              width: Math.max(menuMinWidth.current, MIN_WIDTH),
              marginTop: 2,
            },
          }}
          {...popoverProps}
        >
          <div
            ref={popoverRef}
            data-testid="select-popover"
          >
            <WithSearch
              items={props.children}
              searchOptions={searchOptions}
            >
              {renderMenu ? (
                renderMenu(handleClose)
              ) : (
                <Select handleSelect={handleSelect} />
              )}
            </WithSearch>
          </div>
        </Popover>
      )}
    </div>
  );
};
