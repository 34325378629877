import Router from "components/Router";

import styles from "./styles.module.scss";

export const GuestLayout = () => {
  return (
    <div
      className={styles.layout}
      data-testid="guest-layout"
    >
      <Router />
    </div>
  );
};
