import { useState } from "react";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { Role } from "services/rbac/types";
import { useRbacRoles } from "services/rbac/useRbacRoles";

import { EditRoleModal } from "./EditRoleModal/EditRoleModal";
import { EditModal } from "./Modals/EditModal";
import { useEdit } from "./useEdit";

export const useEditActions = () => {
  const [editRole, setEditRole] = useState<Role | null>(null);

  const flags = useAllFlags();
  const rbacRoles = useRbacRoles();

  const closeEdit = () => {
    setEditRole(null);
  };

  const isRolesWizardEnabled = flags[FeatureFlag.FireboltAppEnableRoleWizard];
  const { isLoading, confirmEdit } = useEdit({ close: closeEdit });

  const handleEdit = (role: Role) => {
    setEditRole(role);
  };

  const onClose = () => {
    if (isLoading) {
      return;
    }
    setEditRole(null);
  };

  const getEditRoleMarkup = () => {
    if (editRole) {
      return isRolesWizardEnabled ? (
        <EditRoleModal
          onClose={onClose}
          role={editRole}
        />
      ) : (
        <EditModal
          editRole={editRole}
          onClose={onClose}
          onConfirm={confirmEdit}
          isLoading={isLoading}
          roles={rbacRoles}
        />
      );
    }
    return null;
  };

  const editRoleMarkup = getEditRoleMarkup();

  return {
    editRole,
    editRoleMarkup,
    handleEdit,
    confirmEdit,
    closeEdit,
  };
};
