enum PrivateLinkFormField {
  Name = "SuppliedName",
  Account = "SuppliedCompany",
  Organization = "Web_Organization__c",
  Email = "SuppliedEmail",
  Subject = "Subject",
  Description = "description",
  Severity = "Severity__c",
  Origin = "Origin",
  Type = "Type",
}

export function prepareDataForRequest(data: any) {
  const formData = {
    [PrivateLinkFormField.Email]: data.email,
    [PrivateLinkFormField.Name]: data.name,
    [PrivateLinkFormField.Account]: data.accountName,
    [PrivateLinkFormField.Organization]: data.organizationName,
    [PrivateLinkFormField.Severity]: "S4 - Question",
    [PrivateLinkFormField.Origin]: "Web",
    [PrivateLinkFormField.Type]: "Platform Event",
    [PrivateLinkFormField.Subject]: `PrivateLink Connection Request - ${data.organizationName} - ${data.organizationId}`,
    [PrivateLinkFormField.Description]: `A request to create a PrivateLink connection for the Firebolt organization was received.
    Organization Name: ${data.organizationName}
    Organization ID: ${data.organizationId}
    Account Name: ${data.accountName}
    Account ID: ${data.accountId}
    AWS Region: ${data.region}
    AWS Principal IDs: ${data.awsId}
    Contact: ${data.name}
    Contact Email: ${data.email}`,
  };
  return JSON.stringify(formData);
}
