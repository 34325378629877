import { Popover } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import EditIcon from "assets/icons/selectMenuIcons/EditIcon.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { WithSearch } from "components/OutlinedSelect/OutlinedSelect";

import { PrivilegesState } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { SelectItems } from "./SelectItems";
import { CLEAR_ALL, SELECT_ALL } from "./constant";

import styles from "./styles.module.scss";

type Props = {
  onChange: (privileges: string[]) => void;
  privileges: { text: string; value: string }[];
  resource: string;
  index: number;
};

export const GrantPrivilegesSelector = (props: Props) => {
  const { t } = useTranslation();
  const { onChange, privileges, index, resource } = props;

  const { menuElement, openMenu, closeMenu, iconElement } = useMenu();
  const { privilegesState } = usePrivilegesState();
  const groupState = privilegesState[resource as keyof PrivilegesState][index];
  const { toAssign, toDeny } = groupState;
  const items = [
    ...(toAssign.length === 0
      ? [
          <ContextMenuItem
            value={SELECT_ALL}
            checkedIconPlaceholder={true}
            checkedOptions={{ indeterminate: true }}
            text={t("roles_wizard.privileges.select_all")}
            testId="select-all-privileges"
            bulkItem
            direction="left"
            skipFilter
            key="all"
            onClick={() => {
              onChange(privileges.map(({ value }) => value));
            }}
          />,
        ]
      : [
          <ContextMenuItem
            value={CLEAR_ALL}
            checked={true}
            checkedIconPlaceholder={true}
            checkedOptions={{ indeterminate: true }}
            testId="clear-all-privileges"
            text={t("roles_wizard.privileges.clear_all")}
            direction="left"
            skipFilter
            key="all"
            onClick={() => {
              onChange([]);
            }}
          />,
        ]),
    <ContextMenuDivider key="divider" />,
    ...privileges.map(privilege => {
      const { text, value } = privilege;
      const disabled = toDeny.includes(value);
      const disabledTooltip = disabled
        ? t("roles_wizard.privileges.disabled_revoke_privilege_tooltip", {
            privilege: text,
          })
        : undefined;
      return (
        <ContextMenuItem
          testId={`add-privilege-${value}`}
          key={value}
          value={value}
          checked={toAssign.includes(value)}
          checkedIconPlaceholder={true}
          text={text}
          direction="left"
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          onClick={() => {
            if (toAssign.includes(value)) {
              onChange(toAssign.filter(item => item !== value));
            } else {
              onChange([...toAssign, value]);
            }
          }}
        />
      );
    }),
  ];

  return (
    <div
      className={styles.grantSelector}
      ref={iconElement}
    >
      {toAssign.length ? (
        <div className={styles.badges}>
          {toAssign.map(privilege => {
            const value = privilege.toLowerCase().replace("usage", "use");
            return (
              <div
                key={privilege}
                className={styles.badge}
              >
                {value}
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={styles.placeholder}
          onClick={openMenu}
          data-testid={`roles-privileges-${resource}-placeholder`}
        >
          {t("roles_wizard.privileges.add_permissions")}
        </div>
      )}
      {!!toAssign.length && (
        <div
          className={classNames(styles.edit, {
            [styles.edit__active]: !!menuElement,
          })}
          onClick={openMenu}
          data-testid={`roles-wizard-grant-privileges-${resource}-${index}`}
        >
          <EditIcon />
        </div>
      )}
      {!!menuElement && (
        <Popover
          open
          anchorEl={iconElement.current}
          classes={{ paper: styles.paper }}
          onClose={closeMenu}
          marginThreshold={8}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              marginTop: 2,
            },
          }}
          data-testid="grant-privileges-selector"
        >
          <WithSearch
            items={items}
            searchOptions={{
              searchPlaceholder: t("roles_wizard.privileges.search"),
            }}
          >
            <SelectItems />
          </WithSearch>
        </Popover>
      )}
    </div>
  );
};
