import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Role } from "services/rbac/types";

import { Step } from "components/Wizard/Step/Step";

import { PrivilegesTable } from "../PrivilegesTable/PrivilegesTable";
import { PrivilegesStepType } from "../types";
import { usePrivileges } from "../usePrivileges";
import { PrivilegesContext } from "../usePrivilegesContext";

type Props = {
  onClose: () => void;
  onSubmit: (data: PrivilegesStepType) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
  initialData: PrivilegesStepType;
  role?: Role;
};

export const PrivilegesStep = (props: Props) => {
  const { onClose, activeStepIndex, onPrevStep, onSubmit, initialData, role } =
    props;
  const { t } = useTranslation();

  const {
    state: privilegesState,
    dispatch: privilegesDispatch,
    getDiff,
  } = usePrivileges(initialData.privileges);

  const context = useMemo(() => {
    return {
      privilegesDispatch,
      privilegesState,
    };
  }, [privilegesState, privilegesDispatch]);

  const body = <PrivilegesTable />;

  const onFormSubmit = () => {
    const submit = {
      privileges: privilegesState,
    };
    if (role) {
      const diff = getDiff(role);
      Object.assign(submit, { diff });
    }
    onSubmit(submit);
  };

  return (
    <PrivilegesContext.Provider value={context}>
      <Step
        title={t("roles_wizard.privileges.title")}
        subtitle={t("roles_wizard.privileges.subtitle")}
        mainActionTitle={t("roles_wizard.assign.step_title")}
        body={body}
        onClose={onClose}
        onSubmit={onFormSubmit}
        activeStepIndex={activeStepIndex}
        onPrevStep={onPrevStep}
        titleTestId="roles-wizard-privileges-title"
      />
    </PrivilegesContext.Provider>
  );
};
