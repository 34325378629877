import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { getDatabaseItems } from "./DatabaseItems";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { GroupInner } from "./GroupInner";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { ViewsItems } from "./ViewsItems";
import { ALL_VIEWS_ALL_DATABASES } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";

import styles from "./styles.module.scss";

const privileges = ["SELECT", "MODIFY"];

type Props = {
  index: number;
  resource: string;
};

export const ViewsGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const { onGrantPrivileges, onRevokePrivileges, deleteGroup } =
    usePrivilegesHandlers({
      index,
      resource,
    });

  const databases = useDatabasesNames();
  const groupState = privilegesState.view[index];
  const { objects } = groupState;
  const menu = useMenu();
  const popoverRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [{ name: ALL_VIEWS_ALL_DATABASES }],
        index,
      });
    }
  }, [dependencies]);

  const menuItems = [
    [
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [],
          });
        },
        testId: "roles-wizard-add-views-group",
      },
    ],
    ...(privilegesState.view.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-vies-group",
            },
          ],
        ]
      : []),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase()),
    };
  });

  const selectedViews = [
    ...new Set<string>(objects.map(object => object.name)),
  ];

  const handleSelect = (items: string[]) => {
    const objects = items.map(item => {
      return {
        name: item,
      };
    });
    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      objects,
      index,
    });
  };

  const bulkItems = [
    <ContextMenuItem
      value={ALL_VIEWS_ALL_DATABASES}
      key={ALL_VIEWS_ALL_DATABASES}
      checked={selectedViews.includes(ALL_VIEWS_ALL_DATABASES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_views")}
      direction="left"
      bulkItem
      skipFilter
      onClick={() => handleSelect([ALL_VIEWS_ALL_DATABASES])}
    />,
  ];

  const databaseItems = getDatabaseItems({
    databases,
    popoverRef,
    renderChildren: (catalogName: string) => (
      <ViewsItems
        catalogName={catalogName}
        index={index}
        resource={resource}
      />
    ),
  });

  const items = [
    ...bulkItems,
    <ContextMenuDivider key="divider" />,
    ...databaseItems,
  ];

  return (
    <div className={styles.group}>
      <GroupInner
        objects={objects}
        items={items}
        resource={resource}
        index={index}
        searchPlaceholder={t("roles_wizard.privileges.search_database")}
      />
      <GrantPrivilegesSelector
        onChange={onGrantPrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <RevokePrivilegeSelector
        onChange={onRevokePrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <Menu
        items={menuItems}
        menu={menu}
        testId={`roles-wizard-${resource}-${index}-menu`}
        alwaysVisible
      />
    </div>
  );
};
