import { systemEngineEnvironment } from "services/environment/systemEngine";

export const getPrivilegesByRole = async (roleName: string) => {
  const sql = `select
  object_type,
  object_name,
  object_catalog,
  privilege_type
from information_schema.object_privileges where grantee = ?`;

  const result = await systemEngineEnvironment.execute<{
    objectType: string;
    objectName: string;
    objectCatalog: string | null;
    privilegeType: string;
  }>(sql, {
    parameters: [roleName],
  });

  const [response] = result;
  return response.rows;
};
