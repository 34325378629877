import classNames from "classnames";

import styles from "./styles.module.scss";

type LoaderProps = {
  btnLoader?: boolean;
  testId?: string;
};

const Balloon: React.FC<{ btnLoader?: boolean; className?: string }> = ({
  btnLoader,
  className,
}) => (
  <div
    className={classNames(styles.ballLoaderBall, className, {
      [styles.smallBall]: btnLoader,
      [styles.defaultBall]: !btnLoader,
    })}
  />
);

const EllipsisLoader: React.FC<LoaderProps> = props => {
  const { btnLoader, testId } = props;
  return (
    <div
      data-testid={testId || "ellipsis-loader"}
      className={`${styles.ballLoader} ${
        btnLoader ? styles.smallLoader : styles.defaultLoader
      }`}
    >
      <Balloon
        btnLoader={btnLoader}
        className={styles.ball1}
      />
      <Balloon
        btnLoader={btnLoader}
        className={styles.ball2}
      />
      <Balloon
        btnLoader={btnLoader}
        className={styles.ball3}
      />
    </div>
  );
};

export default EllipsisLoader;
