import { useTranslation } from "react-i18next";

import CloseIcon from "assets/icons/Close.svg?react";

import { ROLES_WIZARD_IGNORE_ERROR } from "../privilegesReducer";
import { PermissionError } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { MissingDatabasePermission } from "./MissingDatabasePermission";
import { MissingSchemaPermission } from "./MissingSchemaPermission";

import styles from "./styles.module.scss";

const errorTypes = {
  database: MissingDatabasePermission,
  schema: MissingSchemaPermission,
};

type Props = {
  errors: PermissionError[];
  resource: string;
};

export const MissingPermission = (props: Props) => {
  const { errors, resource } = props;
  const filteredErrors = errors.filter(error => !error.ignored);
  const { privilegesDispatch } = usePrivilegesState();

  const { t } = useTranslation();
  const ignorePermissionError = () => {
    for (const error of filteredErrors) {
      privilegesDispatch({
        type: ROLES_WIZARD_IGNORE_ERROR,
        resource,
        error,
      });
    }
  };

  if (!filteredErrors.length) {
    return null;
  }
  return (
    <div
      className={styles.wrapper}
      data-testid="roles-wizard-missing-permissions-error"
    >
      <div className={styles.title}>
        {t("roles_wizard.missing_permission.title")}
        <button className={styles.closeButton}>
          <CloseIcon
            onClick={ignorePermissionError}
            data-testid="roles-wizard-ignore-permission-icon"
          />
        </button>
      </div>

      {filteredErrors.map(error => {
        const Component = errorTypes[error.resource as keyof typeof errorTypes];
        const key = error.catalogName + error.object;
        return (
          <Component
            key={key}
            error={error}
            resource={resource}
          />
        );
      })}
    </div>
  );
};
