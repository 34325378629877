import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { Role } from "services/rbac/types";
import { getGrantStatements, getRevokeStatements } from "services/rbac/utils";
import { getGrantStatement, getRevokeStatement } from "services/users/utils";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import { mapPrivilegeObjects } from "components/RolesWizard/AssignStep/mapPrivilegeObjects";
import { RolesWizard } from "components/RolesWizard/RolesWizard";
import { RolesDataType, RolesWizardStep } from "components/RolesWizard/types";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

type Props = {
  role: Role;
  onClose: () => void;
};

export const EditRoleModal = (props: Props) => {
  const { role, onClose } = props;
  const currentAccount = useCurrentParamsAccount();
  const databases = useDatabasesNames();
  const { data: engines } = useWorkspaceEngines({ includeSystemEngine: false });
  const queryClient = useQueryClient();
  const { putStatusMessage } = useStatusMessageQueue();
  const [isLoading, setIsLoading] = useState(false);

  const confirmEdit = async (
    rolesData: RolesDataType,
    selectedUsers: string[]
  ) => {
    const { grantees } = role;
    const totalGrantees = Array.from(new Set([...grantees, ...selectedUsers]));

    const newGrantees = totalGrantees.filter(
      grantee => !grantees.includes(grantee) && selectedUsers.includes(grantee)
    );

    const revokeGrantees = totalGrantees.filter(
      grantee => grantees.includes(grantee) && !selectedUsers.includes(grantee)
    );

    const dependencies = {
      currentAccount,
      databases,
      engines,
    };

    const { diff } = rolesData[RolesWizardStep.privileges];
    const { toRevoke, toAssign } = diff;

    try {
      setIsLoading(true);

      for (const revoke of toRevoke) {
        // eslint-disable-next-line no-await-in-loop
        const revokePrivileges = await mapPrivilegeObjects(
          revoke.type,
          revoke.actions,
          [revoke.resource],
          dependencies
        );
        for (const privilege of revokePrivileges) {
          const revokeStatements = getRevokeStatements(
            currentAccount.accountName,
            role.roleName,
            privilege
          );
          for (const statement of revokeStatements) {
            // eslint-disable-next-line no-await-in-loop
            await systemEngineEnvironment.execute(statement, {
              database: privilege.catalogName,
            });
          }
        }
      }

      for (const grant of toAssign) {
        // eslint-disable-next-line no-await-in-loop
        const grantPrivileges = await mapPrivilegeObjects(
          grant.type,
          grant.actions,
          [grant.resource],
          dependencies
        );

        for (const privilege of grantPrivileges) {
          const grantStatements = getGrantStatements(
            currentAccount.accountName,
            role.roleName,
            privilege
          );

          for (const statement of grantStatements) {
            // eslint-disable-next-line no-await-in-loop
            await systemEngineEnvironment.execute(statement, {
              database: privilege.catalogName,
            });
          }
        }
      }

      for (const grantee of newGrantees) {
        const statement = getGrantStatement(grantee, role.roleName);
        // eslint-disable-next-line no-await-in-loop
        await systemEngineEnvironment.execute(statement);
      }

      for (const revokeGrantee of revokeGrantees) {
        const statement = getRevokeStatement(revokeGrantee, role.roleName);
        // eslint-disable-next-line no-await-in-loop
        await systemEngineEnvironment.execute(statement);
      }

      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
      onClose();
    } catch (error: any) {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RolesWizard
      onClose={onClose}
      onFinished={confirmEdit}
      disableNameChange
      role={role}
      isLoading={isLoading}
    />
  );
};
