import LoadingOverlap from "../LoadingOverlap";
import { MainSidebarSkeleton } from "../MainSidebar/MainSidebarSkeleton";

import styles from "./styles.module.scss";

const FakePageLoader = ({ title }: { title?: string }) => {
  return (
    <div
      className={styles.app}
      data-testid="fake-page-loader"
    >
      <MainSidebarSkeleton />
      <div className={styles.content}>
        <LoadingOverlap
          isLoading
          title={title}
        />
      </div>
    </div>
  );
};

export default FakePageLoader;
