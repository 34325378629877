import Button, { ButtonTemplate } from "components/Button/Button";

import DatabaseIcon from "./database.svg?react";
import EngineIcon from "./engine.svg?react";

import styles from "./styles.module.scss";

export type Props = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  button: string;
  buttonTemplate?: ButtonTemplate;
  content?: React.ReactNode;
  testId?: string;
  onClick: () => void;
};

export const EmptyState = (props: Props) => {
  const {
    icon,
    title,
    subtitle,
    button,
    onClick,
    testId,
    buttonTemplate,
    content,
  } = props;
  return (
    <div
      className={styles.wrapper}
      data-testid={testId}
    >
      <div className={styles.inner}>
        <div>{icon}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.buttons}>
          {content}
          <Button
            dataTestid={`${testId}-button`}
            text={button}
            template={buttonTemplate ?? ButtonTemplate.Primary}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export { EngineIcon, DatabaseIcon };
