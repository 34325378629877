import { Popover } from "@mui/material";
import classNames from "classnames";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import EditIcon from "assets/icons/selectMenuIcons/EditIcon.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import { WithSearch } from "components/OutlinedSelect/OutlinedSelect";

import { SelectItems } from "./SelectItems";
import { getBulkItemTitle } from "./utils";

import styles from "./styles.module.scss";

type ResourceObject = string | { name: string; catalogName: string };

type Props = {
  objects: ResourceObject[];
  items: React.ReactElement[];
  resource: string;
  index: number;
  searchPlaceholder: string;
};

const MIN_WIDTH = 240;

export const GroupInner = (props: Props) => {
  const { objects, items, resource, index, searchPlaceholder } = props;
  const { menuElement, openMenu, closeMenu } = useMenu();
  const { t } = useTranslation();
  const menuMinWidth = useRef<number>(0);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const setInitialWidth = () => {
    const { width = 0 } = menuRef.current?.getBoundingClientRect() || {};
    menuMinWidth.current = width;
  };

  const handleOpen = (event: React.MouseEvent) => {
    setInitialWidth();
    openMenu(event);
  };

  const renderCatalogItem = (name: React.ReactNode, catalogName: string) => {
    if (catalogName) {
      return (
        <>
          {catalogName}/<span className={styles.resourceName}>{name}</span>
        </>
      );
    }
    return <span className={styles.resourceName}>{name}</span>;
  };

  const renderSpecialValue = (name: string) => {
    const bulkItemTitle = getBulkItemTitle(t, name);
    const resourceName = bulkItemTitle || name;
    return resourceName;
  };

  return (
    <>
      <div
        className={styles.group__inner}
        ref={menuRef}
      >
        <div className={styles.objects}>
          {objects.map(object => {
            const name = typeof object === "string" ? object : object.name;

            const value = renderSpecialValue(name);

            const key =
              typeof object === "string"
                ? object
                : object.name + object.catalogName;

            const listItem =
              typeof object === "string"
                ? value
                : renderCatalogItem(value, object.catalogName);

            return (
              <div
                className={styles.object}
                key={key}
              >
                {listItem}
              </div>
            );
          })}
        </div>
        <div
          className={classNames(styles.edit, {
            [styles.edit__active]: !!menuElement,
          })}
          onClick={handleOpen}
          data-testid={`roles-wizard-edit-objects-${resource}-${index}`}
        >
          <EditIcon />
        </div>
      </div>
      <Popover
        open={!!menuElement}
        anchorEl={menuElement}
        onClose={closeMenu}
        marginThreshold={8}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            minWidth: menuMinWidth.current,
            width: Math.max(menuMinWidth.current, MIN_WIDTH),
            marginTop: 2,
          },
        }}
        data-testid={`edit-objects-${resource}`}
      >
        <WithSearch
          items={items}
          searchOptions={{
            searchPlaceholder,
          }}
        >
          <SelectItems />
        </WithSearch>
      </Popover>
    </>
  );
};
