import { systemEngineEnvironment } from "services/environment/systemEngine";

import { getPrivilegesByRole } from "./getPrivilegesByRole";

export const revokeRolePrivileges = async (roleName: string) => {
  const privileges = await getPrivilegesByRole(roleName);

  for (const privilege of privileges) {
    const { objectType, objectName, privilegeType, objectCatalog } = privilege;
    const statement = `REVOKE ${privilegeType} ON ${objectType} "${objectName}" FROM "${roleName}";`;
    const options = objectCatalog ? { database: objectCatalog } : undefined;
    // eslint-disable-next-line no-await-in-loop
    await systemEngineEnvironment.execute(statement, options);
  }

  return null;
};
