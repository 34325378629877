import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Column, useColumns } from "services/columns/useColumns";
import { useIndexes } from "services/indexes/useIndexes";

import ColumnFilters from "components/ColumnFilters";
import { isDataTypeConversionValid } from "components/Datagrid/utils/data";
import RadioButton from "components/RadioButton/RadioButton";
import { Step } from "components/Wizard/Step/Step";

import { normalizeDestinationData } from "../Steps/utils";
import {
  IngestionStep,
  MapDataStep,
  MapExistingTableStep,
  MapType,
  PreviewData,
  WizardDataType,
} from "../types";
import { ExistingTable } from "./ExistingTable/ExistingTable";
import { ExistingTableMapData, NULL_TYPE } from "./ExistingTable/types";
import { useVisibleColumns } from "./useVisibleColumns";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSubmit: (data: MapExistingTableStep | MapDataStep) => void;
  wizardData: WizardDataType;
  onPrevStep: () => void;
  activeStepIndex: number;
  initialData: MapExistingTableStep | MapDataStep;
};

const tableHeader = [
  {
    displayName: "wizard.map_data.target_name",
    name: "name",
    type: "",
    disabled: true,
  },
  {
    displayName: "wizard.map_data.type",
    name: "type",
    type: "",
    disabled: true,
  },
  {
    displayName: "wizard.map_data.nullable",
    name: "nullable",
    type: "",
    disabled: true,
  },
  {
    displayName: "wizard.map_data.primaryIndex",
    name: "primaryIndex",
    type: "",
    disabled: true,
  },
  {
    displayName: "wizard.map_data.source_name",
    name: "source_name",
    type: "",
  },
  {
    displayName: "wizard.map_data.source_type",
    name: "source_type",
    type: "",
  },
  {
    displayName: "wizard.map_data.data_preview",
    name: "dataPreview",
    type: "",
  },
];

const normalizeData = (
  preview: PreviewData,
  tableColumns: Column[],
  mapType: MapType | null
): ExistingTableMapData[] => {
  return tableColumns.map((column, index) => {
    const result = {
      name: column.columnName,
      type: column.dataType,
      isNullable: column.isNullable,
      source_name: "",
      source_type: "",
      dataPreview: "",
      isSourceTypeCompatibleWithDestination: true,
    };

    let source = null;
    if (mapType === MapType.name) {
      source = preview.meta.find(
        c => c.name.toLowerCase() === column.columnName.toLowerCase()
      );
    }

    if (mapType === MapType.index) {
      source = preview.meta[index];
    }

    if (source) {
      const { type, name } = source;
      Object.assign(result, {
        source_type: type,
        source_name: name,
        dataPreview: preview.data
          .slice(0, Math.min(20, preview.data.length))
          .map(d => d[name])
          .join(","),
        isSourceTypeCompatibleWithDestination: isDataTypeConversionValid({
          from: type,
          to: result.type,
        }),
      });
    } else {
      Object.assign(result, {
        source_name: null,
      });
    }
    return result;
  });
};

export const MapExistingTable = (props: Props) => {
  const {
    onSubmit,
    onPrevStep,
    activeStepIndex,
    wizardData,
    initialData,
    onClose,
  } = props;

  const [mapType, setMapType] = useState<MapType | null>(
    (initialData as MapExistingTableStep)?.mapType === undefined
      ? MapType.name
      : (initialData as MapExistingTableStep)?.mapType
  );
  const [errors, setErrors] = useState({});

  const destination = wizardData[IngestionStep.selectDestination];
  const { tableName, databaseName } = normalizeDestinationData(destination);
  const tableColumns = useColumns(databaseName, tableName);
  const tableIndexes = useIndexes(databaseName, tableName);
  const { filterColumns, visibleColumns, columns } =
    useVisibleColumns(tableHeader);

  const indexes = tableIndexes.reduce<number[]>((acc, primaryIndex) => {
    const { indexDefinition } = primaryIndex;
    return indexDefinition
      .replace(/^[[]|[\]]$/g, "")
      .split(",")
      .map((column: string) => {
        return tableColumns.findIndex(c => c.columnName === column);
      });
  }, []);

  const previewData = wizardData[IngestionStep.formatData]?.previewData!;

  const [data, setData] = useState<MapExistingTableStep["data"]>(
    () =>
      (initialData as MapExistingTableStep)?.data ||
      normalizeData(previewData, tableColumns, mapType)
  );
  const { t } = useTranslation();

  const handleChangeMapType = (mapType: MapType) => () => {
    const data = normalizeData(previewData, tableColumns, mapType);
    setData(data);
    setMapType(mapType);
    setErrors({});
  };

  const onChangeSource = (index: number, sourceName: string) => {
    let source = previewData.meta.find(column => column.name === sourceName);
    if (sourceName === NULL_TYPE) {
      source = {
        name: NULL_TYPE,
        type: "",
      };
    }
    if (!source) {
      return;
    }
    const { name, type } = source;
    setData(data => {
      return data.map((column, i) => {
        if (i === index) {
          return {
            ...column,
            source_name: name,
            source_type: type,
          };
        }
        return column;
      });
    });
    setMapType(null);
    setErrors({});
  };

  const onFormSubmit = () => {
    const errors = data.reduce<Record<string, boolean>>((acc, column) => {
      if (!column.source_name && !column.isNullable) {
        acc[column.name] = true;
      }

      if (!column.isSourceTypeCompatibleWithDestination) {
        acc[`${column.name}_${column.source_type}`] = true;
      }

      return acc;
    }, {});

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    onSubmit({
      data,
      mapType,
    });
  };

  const body = (
    <div className={styles.mapData}>
      <div className={styles.mapping}>
        <div className={styles.mapping__title}>
          {t("wizard.map_data.mapping")}
        </div>
        <div className={styles.mapping__group}>
          <div>
            <div className={styles.mapping__radio}>
              <RadioButton
                selected={mapType === MapType.name}
                onClick={handleChangeMapType(MapType.name)}
              />
              <div className={styles.mapping__type}>
                {t("wizard.map_data.mapping_name")}
              </div>
            </div>
            <div className={styles.mapping__description}>
              {t("wizard.map_data.mapping_name_description")}
            </div>
          </div>
          <div>
            <div className={styles.mapping__radio}>
              <RadioButton
                selected={mapType === MapType.index}
                onClick={handleChangeMapType(MapType.index)}
              />
              <div className={styles.mapping__type}>
                {t("wizard.map_data.mapping_index")}
              </div>
            </div>
            <div className={styles.mapping__description}>
              {t("wizard.map_data.mapping_index_description")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tableControls}>
        <div className={styles.tableTitle}>
          {t("wizard.map_data.table_title")}
        </div>
        <div className={styles.actions}>
          <div className={styles.columnFilterIcon}>
            <ColumnFilters
              columns={tableHeader}
              visibleColumns={visibleColumns}
              handleColumnClick={filterColumns}
            />
          </div>
        </div>
      </div>
      <ExistingTable
        wizardData={wizardData}
        indexes={indexes}
        data={data}
        errors={errors}
        columns={columns}
        visibleColumns={visibleColumns}
        onChangeSource={onChangeSource}
      />
    </div>
  );
  return (
    <Step
      title={t("wizard.map_data.title")}
      subtitle={t("wizard.map_data.subtitle")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      onPrevStep={onPrevStep}
      activeStepIndex={activeStepIndex}
    />
  );
};
