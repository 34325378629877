import _uniq from "lodash/uniq";
import _uniqBy from "lodash/uniqBy";

import { Role } from "services/rbac/types";

type Group = {
  toAssign: string[];
  toDeny: string[];
  objects: any[];
};

type Privileges = {
  engine: Group[];
  database: Group[];
  account: Group[];
  view: Group[];
  schema: Group[];
  table: Group[];
};

const isDatabaseLevel = (objectType: string) => {
  return (
    objectType === "table" || objectType === "view" || objectType === "schema"
  );
};

export const getPrivilegesFromRole = (role: Role) => {
  const privileges: Privileges = {
    engine: [{ toAssign: [], toDeny: [], objects: [] }],
    database: [{ toAssign: [], toDeny: [], objects: [] }],
    account: [{ toAssign: [], toDeny: [], objects: [] }],
    view: [{ toAssign: [], toDeny: [], objects: [] }],
    schema: [{ toAssign: [], toDeny: [], objects: [] }],
    table: [{ toAssign: [], toDeny: [], objects: [] }],
  };

  for (const privilege of role.privileges) {
    const { objectName, objectType, privilegeType } = privilege;
    if (objectType in privileges) {
      const targetGroup = privileges[
        objectType as keyof typeof privileges
      ].findIndex(group => {
        const { objects, toAssign } = group;
        if (!objects.length && !toAssign.length) {
          return true;
        }

        if (toAssign.includes(privilegeType)) {
          return true;
        }

        if (isDatabaseLevel(objectType)) {
          return objects.some(
            object =>
              object.name === objectName &&
              object.catalogName === privilege.objectCatalog
          );
        }

        return objects.includes(objectName);
      });

      if (targetGroup !== -1) {
        privileges[objectType as keyof typeof privileges][targetGroup] = {
          ...privileges[objectType as keyof typeof privileges][targetGroup],
          objects: isDatabaseLevel(objectType)
            ? _uniqBy(
                [
                  ...privileges[objectType as keyof typeof privileges][
                    targetGroup
                  ].objects,
                  { objectName, catalogName: privilege.objectCatalog },
                ],
                ["objectName", "catalogName"]
              )
            : _uniq([
                ...privileges[objectType as keyof typeof privileges][
                  targetGroup
                ].objects,
                objectName,
              ]),
          toAssign: _uniq([
            ...privileges[objectType as keyof typeof privileges][targetGroup]
              .toAssign,
            privilegeType,
          ]),
        };
      } else {
        privileges[objectType as keyof typeof privileges].push({
          objects: isDatabaseLevel(objectType)
            ? [{ catalogName: privilege.objectCatalog, objectName }]
            : [objectName],
          toAssign: [privilegeType],
          toDeny: [],
        });
      }
    }
  }
  return privileges;
};
