import {
  ROLES_WIZARD_DELETE_GROUP,
  ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
  ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";

export const usePrivilegesHandlers = ({
  resource,
  index,
}: {
  resource: string;
  index: number;
}) => {
  const { privilegesDispatch } = usePrivilegesState();

  const onGrantPrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const onRevokePrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const onSelectResourceObjects = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      objects: items,
      index,
    });
  };

  const deleteGroup = () => {
    privilegesDispatch({
      type: ROLES_WIZARD_DELETE_GROUP,
      resource,
      index,
    });
  };

  return {
    onGrantPrivileges,
    onRevokePrivileges,
    onSelectResourceObjects,
    deleteGroup,
  };
};
