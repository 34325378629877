import classNames from "classnames";
import _orderBy from "lodash/orderBy";
import React from "react";

import { SystemRole } from "services/rbac/constants";
import { Role } from "services/rbac/types";

import { useEditActions } from "pages/govern/Roles/useEditActions";
import { useViewActions } from "pages/govern/Roles/useViewActions";

import { EmptySearch } from "../EmptyState/EmptySearch";
import { RoleRecord } from "./RoleRecord";
import { OrderBy, OrderDirection } from "./types";
import { useDeleteRole } from "./useDeleteRole";

import styles from "./styles.module.scss";

type Props = {
  items: Role[];
  order: {
    orderBy: OrderBy;
    orderDirection: OrderDirection;
  };
  handleView: ReturnType<typeof useViewActions>["handleView"];
  search: string;
};

export const RoleList = (props: Props) => {
  const { search, items, order, handleView } = props;
  const { deleteRole, deleteRoleMarkup } = useDeleteRole();

  const { editRoleMarkup, handleEdit } = useEditActions();

  const filtered = items.filter(item => {
    return item.roleName.toLowerCase().includes(search.toLowerCase());
  });

  const sortRoles = (roles: Role[]) => {
    return _orderBy(
      roles,
      [
        (role: Role) => {
          if (order.orderBy === OrderBy.created) {
            return role.created;
          }

          return role.roleName.toLowerCase();
        },
      ],
      [order.orderDirection]
    );
  };

  const systemRoles = sortRoles(
    filtered.filter(role =>
      Object.values(SystemRole).includes(role.roleName as SystemRole)
    )
  );
  const userRoles = sortRoles(
    filtered.filter(
      role => !Object.values(SystemRole).includes(role.roleName as SystemRole)
    )
  );

  const sortedAll = [...systemRoles, ...userRoles];

  const renderRole = (role: Role) => {
    return (
      <RoleRecord
        role={role}
        key={role.roleName}
        deleteRole={deleteRole}
        handleView={handleView}
        handleEdit={handleEdit}
      />
    );
  };

  return (
    <div
      className={classNames(styles.section, {
        [styles.flex]: !!search && !sortedAll.length,
      })}
    >
      {systemRoles.map(renderRole)}
      {!!(systemRoles.length && userRoles.length) && (
        <div
          className={styles.divider}
          key="system-roles-divider"
        />
      )}
      {userRoles.map(renderRole)}
      {!!search && !sortedAll.length && (
        <EmptySearch
          search={search}
          testId="empty-roles-search"
        />
      )}
      {deleteRoleMarkup}
      {editRoleMarkup}
    </div>
  );
};
