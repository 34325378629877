import _orderBy from "lodash/orderBy";
import { sizeUnits } from "utils/helpers/Format";

import { Row } from "../types";
import { isBooleanType, isDateType, isNumberType } from "./data";

export const SIZE_FIELDS = ["size", "size_uncompressed"];
export const NUMBER_OR_NA_FIELDS = ["number_of_rows", "compression_ratio"];
const NA_VALUE = "N/A";

const tryGetUnit = (
  row: {
    [x: string]: string;
  },
  field: string
) => {
  const textValue = row[field];

  const defaultValue = {
    value: textValue,
    ok: false,
  };

  const unit = Object.keys(sizeUnits).find(
    unit => typeof textValue === "string" && textValue.includes(unit)
  );

  if (unit) {
    const multiplicator = sizeUnits[unit as keyof typeof sizeUnits];
    const parsedNumber = Number.parseFloat(textValue);
    if (multiplicator && !Number.isNaN(parsedNumber)) {
      return { value: parsedNumber * multiplicator, ok: true };
    }
    return defaultValue;
  }

  return defaultValue;
};

interface SortInterface {
  rows: { [x: string]: any }[];
  sortOrder: string;
  name: string;
}

const sortBySize = ({ rows, sortOrder, name }: SortInterface) => {
  return _orderBy(
    rows,
    (row: { [x: string]: string }) => {
      const { value, ok } = tryGetUnit(row, name);

      if (value === null) {
        return sortOrder === "asc" ? Infinity : -Infinity;
      }

      if (ok) {
        return value;
      }
      if (value === NA_VALUE) {
        return -1;
      }
      return row[name];
    },
    // @ts-ignore
    [sortOrder]
  );
};

const sortNa = ({ rows, sortOrder, name }: SortInterface) => {
  return _orderBy(
    rows,
    (row: { [x: string]: string }) => {
      const value = row[name];
      if (value === NA_VALUE) {
        return -1;
      }

      if (value === null) {
        return sortOrder === "asc" ? Infinity : -Infinity;
      }

      const numberValue = Number.parseFloat(value);

      if (!Number.isNaN(numberValue)) {
        return numberValue;
      }

      return value;
    },
    // @ts-ignore
    [sortOrder]
  );
};

const sortNumber = ({ rows, sortOrder, name }: SortInterface) => {
  return _orderBy(
    rows,
    (row: { [x: string]: string }) => {
      const value = row[name];
      if (value === null) {
        return sortOrder === "asc" ? Infinity : -Infinity;
      }
      return Number(value);
    },
    // @ts-ignore
    [sortOrder]
  );
};

const sortDate = ({ rows, sortOrder, name }: SortInterface) => {
  return _orderBy(
    rows,
    (row: { [x: string]: string }) => {
      const value = row[name];
      if (value === null) {
        return sortOrder === "asc" ? Infinity : -Infinity;
      }
      return new Date(value);
    },
    // @ts-ignore
    [sortOrder]
  );
};

const sortBoolean = ({ rows, sortOrder, name }: SortInterface) => {
  return [...rows].sort((a, b) => {
    const A = sortOrder === "asc" ? +a[name] : +b[name];
    const B = sortOrder === "asc" ? +b[name] : +a[name];

    if (a[name] === null) {
      return Infinity;
    }

    if (b[name] === null) {
      return -Infinity;
    }

    return A - B;
  });
};

const defaultSort = ({ rows, sortOrder, name }: SortInterface) => {
  return [...rows].sort((a, b) => {
    const A = (sortOrder === "asc" ? a[name] : b[name]) || "";
    const B = (sortOrder === "asc" ? b[name] : a[name]) || "";

    if (a[name] === null) {
      return Infinity;
    }

    if (b[name] === null) {
      return -Infinity;
    }

    return A.toString().localeCompare(B.toString(), undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
};

export const sortRows = ({
  rows,
  destinationSortConfig,
  column,
  originalRows,
}: {
  rows: {
    [x: string]: string | number;
  }[];
  destinationSortConfig: {
    sortOrder: string;
  };
  column: {
    name: string;
    type: string;
  };
  originalRows: React.MutableRefObject<Row[]>;
}) => {
  const { sortOrder } = destinationSortConfig;
  const { name, type } = column;

  const comparator = (field: string) =>
    field.toUpperCase() === name.toUpperCase();

  const isSizeFields = !!SIZE_FIELDS.find(comparator);
  const isNumberNAFields = !!NUMBER_OR_NA_FIELDS.find(comparator);

  const props = {
    rows,
    sortOrder,
    name,
  };

  if (sortOrder === "original") {
    return originalRows.current;
  }

  if (isSizeFields) {
    return sortBySize(props);
  }

  if (isNumberNAFields) {
    return sortNa(props);
  }

  if (isNumberType(type.toUpperCase())) {
    return sortNumber(props);
  }

  if (isDateType(type.toUpperCase())) {
    return sortDate(props);
  }

  if (isBooleanType(type.toUpperCase())) {
    return sortBoolean(props);
  }

  return defaultSort(props);
};
