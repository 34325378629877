import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { createRole } from "services/rbac/createRole";
import { deleteRole } from "services/rbac/deleteRole";
import { getGrantStatements, getRevokeStatements } from "services/rbac/utils";
import { getGrantStatement } from "services/users/utils";

import { Privilege } from "pages/govern/Roles/PrivilegesTransaction";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import { mapPrivilegeObjects } from "components/RolesWizard/AssignStep/mapPrivilegeObjects";
import { RolesWizard } from "components/RolesWizard/RolesWizard";
import { RolesDataType, RolesWizardStep } from "components/RolesWizard/types";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

const types = ["account", "database", "engine", "schema", "table", "view"];

export const useCreateRole = () => {
  const [role, setCreateRole] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const databases = useDatabasesNames();
  const { data: engines } = useWorkspaceEngines({ includeSystemEngine: false });
  const currentAccount = useCurrentParamsAccount();

  const dependencies = {
    currentAccount,
    databases,
    engines,
  };

  const openCreateRole = () => {
    setCreateRole(true);
  };

  const closeCreateRole = () => {
    if (isLoading) {
      return;
    }
    setCreateRole(false);
  };

  const confirmCreate = async (
    rolesData: RolesDataType,
    selectedUsers: string[]
  ) => {
    const { name } = rolesData.name;
    let roleCreated = false;

    const grantRoleToUsers = async () => {
      const { name } = rolesData[RolesWizardStep.name];
      for (const user of selectedUsers) {
        const statement = getGrantStatement(user, name);
        // eslint-disable-next-line no-await-in-loop
        await systemEngineEnvironment.execute(statement);
      }
    };

    const getAndExecuteStatements = async (
      getStatements: (
        accountName: string,
        roleName: string,
        grant: Privilege
      ) => string[],
      privileges: {
        type: string;
        resource: string;
        catalogName: string | undefined;
        actions: string[];
      }[]
    ) => {
      for (const item of privileges) {
        const statements = getStatements(
          currentAccount.accountName,
          name,
          item
        );
        for (const statement of statements) {
          // eslint-disable-next-line no-await-in-loop
          await systemEngineEnvironment.execute(statement, {
            database: item.catalogName,
          });
        }
      }
    };

    const revokeGroupPrivileges = async (
      type: string,
      group: {
        toAssign: string[];
        toDeny: string[];
        objects: string[] | { name: string; catalogName: string }[];
      }
    ) => {
      const { toDeny, objects } = group;
      const revokePrivileges = await mapPrivilegeObjects(
        type,
        toDeny,
        objects,
        dependencies
      );
      await getAndExecuteStatements(getRevokeStatements, revokePrivileges);
    };

    const grantGroupPrivileges = async (
      type: string,
      group: {
        toAssign: string[];
        toDeny: string[];
        objects: string[] | { name: string; catalogName: string }[];
      }
    ) => {
      const { toAssign, objects } = group;
      const grantPrivileges = await mapPrivilegeObjects(
        type,
        toAssign,
        objects,
        dependencies
      );
      await getAndExecuteStatements(getGrantStatements, grantPrivileges);
    };

    try {
      const { privileges } = rolesData.privileges;
      if (!privileges) {
        return;
      }

      setIsLoading(true);
      await createRole(name);
      roleCreated = true;

      for (const type of types) {
        const privilegesGroups =
          privileges[type as keyof typeof privileges] || [];
        for (const group of privilegesGroups as []) {
          // eslint-disable-next-line no-await-in-loop
          await revokeGroupPrivileges(type, group);
          // eslint-disable-next-line no-await-in-loop
          await grantGroupPrivileges(type, group);
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });

      await grantRoleToUsers();
      putStatusMessage({
        message: t("roles.create_modal.success"),
        type: StatusMessageType.Success,
      });

      closeCreateRole();
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
      if (roleCreated) {
        await deleteRole(name);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createRoleMarkup = role ? (
    <RolesWizard
      onClose={closeCreateRole}
      onFinished={confirmCreate}
      isLoading={isLoading}
    />
  ) : null;

  return {
    createRoleMarkup,
    openCreateRole,
  };
};
