import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { GroupInner } from "./GroupInner";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { ALL_ENGINES } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";
import { getOtherGroupsObjects } from "./utils";

import styles from "./styles.module.scss";

const privileges = ["USAGE", "OPERATE", "MODIFY"];

type Props = {
  index: number;
  resource: string;
};

export const EnginesGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const menu = useMenu();
  const { data: engines = [] } = useWorkspaceEngines();

  const { t } = useTranslation();

  const {
    onGrantPrivileges,
    onRevokePrivileges,
    onSelectResourceObjects,
    deleteGroup,
  } = usePrivilegesHandlers({ index, resource });

  const groupState = privilegesState.engine[index];

  const otherGroupsObjects = getOtherGroupsObjects(
    privilegesState.engine,
    index
  );

  const { objects } = groupState;

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [ALL_ENGINES],
        index,
      });
    }
  }, [dependencies]);

  const menuItems = [
    [
      ...(objects.includes(ALL_ENGINES)
        ? []
        : [
            {
              key: "add_remaining",
              text: t("roles_wizard.privileges.menu.add_remaining_engines"),
              action: () => {
                privilegesDispatch({
                  type: ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
                  resource,
                  allObjects: engines.map(({ engineName }) => engineName),
                });
              },
              testId: "roles-wizard-add-remaining-engines",
            },
          ]),
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [ALL_ENGINES],
          });
        },
        testId: "roles-wizard-add-engines-group",
      },
    ],

    ...(privilegesState.engine.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-engines-group",
            },
          ],
        ]
      : []),
  ];

  const items = [
    <ContextMenuItem
      value={ALL_ENGINES}
      key={ALL_ENGINES}
      checked={objects.includes(ALL_ENGINES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_engines")}
      direction="left"
      bulkItem
      skipFilter
      onClick={() =>
        onSelectResourceObjects(
          objects.includes(ALL_ENGINES) ? [] : [ALL_ENGINES]
        )
      }
    />,
    <ContextMenuDivider key="divider" />,
    ...engines.map(engine => {
      const { engineName } = engine;
      const disabled = otherGroupsObjects[engineName];
      const disabledTooltip = disabled
        ? t("roles_wizard.privileges.disabled_engine_tooltip", {
            engine: engineName,
          })
        : undefined;
      return (
        <ContextMenuItem
          value={engineName}
          key={engineName}
          checked={objects.includes(engineName)}
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          checkedIconPlaceholder={true}
          text={engineName}
          direction="left"
          onClick={() => {
            objects.includes(engineName)
              ? onSelectResourceObjects(
                  objects
                    .filter(obj => obj !== engineName)
                    .filter(obj => obj !== ALL_ENGINES)
                )
              : onSelectResourceObjects(
                  [...objects, engineName].filter(obj => obj !== ALL_ENGINES)
                );
          }}
        />
      );
    }),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase().replace("usage", "use")),
    };
  });

  return (
    <div className={styles.group}>
      <GroupInner
        objects={objects}
        items={items}
        resource={resource}
        index={index}
        searchPlaceholder={t("roles_wizard.privileges.search_engine")}
      />
      <GrantPrivilegesSelector
        onChange={onGrantPrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <RevokePrivilegeSelector
        onChange={onRevokePrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <Menu
        items={menuItems}
        menu={menu}
        testId={`roles-wizard-${resource}-${index}-menu`}
        alwaysVisible
      />
    </div>
  );
};
