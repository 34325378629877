export enum SystemDatabase {
  ACCOUNT = "account_db",
  ORG = "org_db",
}

export const SYSTEM_DATABASE_NAMES = [
  SystemDatabase.ACCOUNT,
  SystemDatabase.ORG,
];

export const isSystemDatabase = (databaseName: string) =>
  SYSTEM_DATABASE_NAMES.includes(databaseName as SystemDatabase);
