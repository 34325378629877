import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Transaction } from "pages/govern/Roles/PrivilegesTransaction";

import { getGrantStatements, getRevokeStatements } from "./utils";

export const editRole = async (
  accountName: string,
  transaction: Transaction
) => {
  const { initialRoleName, toAssign, toRevoke } = transaction;

  for (const revoke of toRevoke) {
    const revokeStatements = getRevokeStatements(
      accountName,
      initialRoleName,
      revoke
    );
    for (const statement of revokeStatements) {
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }
  }

  for (const grant of toAssign) {
    const grantStatements = getGrantStatements(
      accountName,
      initialRoleName,
      grant
    );

    for (const statement of grantStatements) {
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }
  }
};
