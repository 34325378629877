const withNullableTypes = (types: string[]): string[] => {
  return types.reduce(
    (acc, type) => [...acc, type, `${type} NULL`, `${type} NOT NULL`],
    [] as string[]
  );
};

const withArrayTypes = (types: string[]): string[] => {
  return types.reduce(
    (acc, type) => [...acc, type, `ARRAY(${type})`],
    [] as string[]
  );
};

export const TIMESTAMP_TYPES = withNullableTypes([
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMESTAMPNTZ",
  "DATETIME",
]);

export const DATE_TYPES = withNullableTypes([
  "DATE",
  "PGDATE",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMESTAMPNTZ",
  "DATETIME",
]);

export const FLOAT_TYPES = withNullableTypes([
  "FLOAT",
  "DOUBLE",
  "DECIMAL",
  "REAL",
  "DOUBLE PRECISION",
  "NUMERIC",
]);

export const BOOLEAN_TYPES = withNullableTypes(["BOOLEAN"]);

export const INTEGER_TYPES = withNullableTypes([
  "INT",
  "INTEGER",
  "LONG",
  "BIGINT",
]);

export const STRING_TYPES = withNullableTypes(["STRING", "TEXT"]);

export const ARRAY_STRING_TYPES = withArrayTypes(STRING_TYPES);

export const NUMBER_TYPES = [...INTEGER_TYPES, ...FLOAT_TYPES];

export const GEOMETRY_TYPES = [
  "POINT",
  "LINESTRING",
  "POLYGON",
  "MULTIPOINT",
  "MULTILINESTRING",
  "MULTIPOLYGON",
  "GEOMETRYCOLLECTION",
];

export const BYTEA_TYPES = withNullableTypes(["BYTEA"]);

export const isGeometryType = (type: string | undefined) => {
  return !!type && GEOMETRY_TYPES.some(gType => type.includes(gType));
};

export const isByteaType = (type: string | undefined) => {
  return !!type && BYTEA_TYPES.includes(type);
};

export const isDateType = (type: string | undefined) => {
  return !!type && DATE_TYPES.includes(type);
};

export const isTimestampType = (type: string) => {
  return TIMESTAMP_TYPES.includes(type);
};

export const isDecimalType = (type: string) => {
  return !!type.match(/^(decimal|numeric)(.+)?/i);
};

export const isNumberType = (type: string | undefined) => {
  return !!type && (NUMBER_TYPES.includes(type) || isDecimalType(type));
};

export const isStringType = (type: string) => {
  return STRING_TYPES.includes(type);
};

export const isBooleanType = (type: string) => {
  return BOOLEAN_TYPES.includes(type);
};

export const isArrayType = (type: string | undefined) => {
  return !!type && type.startsWith("ARRAY");
};

export const isStringArray = (type: string | undefined) => {
  return !!type && ARRAY_STRING_TYPES.includes(type);
};

export const isDataTypeConversionValid = ({
  from,
  to,
}: {
  from: string;
  to: string;
}): boolean => {
  const upperFrom = from.toUpperCase();
  const upperTo = to.toUpperCase();

  if (isDecimalType(upperFrom)) {
    if (!isDecimalType(upperTo)) return false;

    const fromPrecisionAndScale = upperFrom.split(" NULL")[0].split("(")[1];
    const toPrecisionAndScale = upperTo.split("(")[1];

    return fromPrecisionAndScale === toPrecisionAndScale;
  }

  if (isArrayType(upperFrom)) {
    return isArrayType(upperTo);
  }

  if (isNumberType(upperFrom)) {
    return isNumberType(upperTo) || isStringType(upperTo);
  }

  if (isStringType(upperFrom)) {
    return (
      isStringType(upperTo) ||
      NUMBER_TYPES.includes(upperTo) ||
      isDateType(upperTo) ||
      isGeometryType(upperTo) ||
      isStringArray(upperTo)
    );
  }

  if (isByteaType(upperFrom)) {
    return isByteaType(upperTo) || isGeometryType(upperTo);
  }

  if (isBooleanType(upperFrom)) {
    return isBooleanType(upperTo) || isStringType(upperTo);
  }

  if (isDateType(upperFrom)) {
    return isDateType(upperTo) || isStringType(upperTo);
  }

  if (isGeometryType(upperFrom)) {
    return (
      isGeometryType(upperTo) || isByteaType(upperTo) || isStringType(upperTo)
    );
  }

  return true;
};
