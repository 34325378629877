export enum RolesWizardStep {
  name = "name",
  privileges = "privileges",
  assign = "assign",
}

export type NameStepType = {
  name: string;
};

export type AssignStepType = {
  grantee: string[] | null;
};

export type PrivilegesStepType = {
  privileges: PrivilegesState | null;
  diff?: any;
};

export type RolesDataType = {
  [RolesWizardStep.name]: NameStepType;
  [RolesWizardStep.privileges]: PrivilegesStepType;
  [RolesWizardStep.assign]: AssignStepType;
};

export type PermissionError = {
  object: string;
  resource: string;
  action: string;
  catalogName?: string;
  ignored?: boolean;
  errorSource: {
    type: string;
    name: string;
    actions: string[];
    catalogName: string;
  };
};

export type PrivilegesState = {
  engine: {
    toAssign: string[];
    toDeny: string[];
    objects: string[];
    expanded?: boolean;
  }[];
  account: {
    toAssign: string[];
    toDeny: string[];
    expanded?: boolean;
    objects: string[];
  }[];
  database: {
    toAssign: string[];
    toDeny: string[];
    objects: string[];
    expanded?: boolean;
  }[];
  table: {
    toAssign: string[];
    toDeny: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
  }[];
  view: {
    toAssign: string[];
    toDeny: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
  }[];
  schema: {
    toAssign: string[];
    toDeny: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
  }[];
};

export type PrivilegesErrors = {
  permissionErrors: {
    table: PermissionError[];
    view: PermissionError[];
    schema: PermissionError[];
  };
};

export type PrivilegesStateWithErrors = PrivilegesState & PrivilegesErrors;
