import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactQueryKeysAccount } from "services/queryKeys";
import { editRole } from "services/rbac/editRole";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

import { Transaction } from "./PrivilegesTransaction";

export const useEdit = ({ close }: { close: () => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { putStatusMessage } = useStatusMessageQueue();
  const currentAccount = useCurrentParamsAccount();
  const { t } = useTranslation();
  const confirmEdit = async (transaction: Transaction) => {
    try {
      setIsLoading(true);
      await editRole(currentAccount.accountName, transaction);
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
      close();
      putStatusMessage({
        message: t("roles.edit_modal.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    confirmEdit,
  };
};
