import classNames from "classnames";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOrganization } from "hooks/useOrganization";
import { useAccounts } from "services/account/useAccounts";
import { useUser } from "services/auth/useUser";
import { useLogin } from "services/login/useLogin";
import { OrganizationService } from "services/organization";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import FormModal from "components/FormModal/FormModal";
import LoadingOverlap from "components/LoadingOverlap";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import {
  getBackendErrorMessage,
  getContactSupportURL,
} from "../ContactSupport/helpers";
import { Translations } from "../ContactSupport/translations";
import { FormFields, PrivateLink } from "./PrivateLink";
import { prepareDataForRequest } from "./helpers";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
};

export const PrivateLinkCreate = (props: Props) => {
  const { onClose } = props;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const { getAccount } = useCurrentAccount();
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();
  const account = getAccount();
  const { data: organization } = useOrganization();
  const accounts = useAccounts();
  const login = useLogin();
  const user = useUser();

  const onSubmitSuccess = () => {
    putStatusMessage({
      message: (
        <>
          <div>{t(Translations.successSubmitMessage1)}</div>
          <div>{t(Translations.successSubmitMessage2)}</div>
        </>
      ),
      type: StatusMessageType.Success,
    });
    props.onClose();
  };

  const onSubmitError = (errMessage?: string) => {
    putStatusMessage({
      message: errMessage || t(Translations.errorSubmitMessage),
      type: StatusMessageType.Error,
      options: {
        insertToPosition: StatusMessagePosition.Bottom,
        id: null,
        autoRemove: true,
        autoClearDelay: 10000,
      },
    });
  };

  const handleSubmit = async (fields: FormFields) => {
    try {
      setSubmitting(true);

      const selectedAccount = accounts.find(
        acc => acc.accountName === fields.accountName
      );
      const organizationData = await OrganizationService.isSupported(
        organization?.organizationName ?? ""
      );
      const data = {
        name: `${login.firstName} ${login.lastName}`,
        email: user.email,
        accountName: selectedAccount?.accountName ?? "",
        accountId: selectedAccount?.id ?? "",
        region: selectedAccount?.region ?? "",
        organizationName: organization?.organizationName ?? "",
        organizationId: organizationData?.organizationID ?? "",
        awsId: fields.awsId ?? "",
      };
      const params = prepareDataForRequest(data);
      const contactSupportUrl = getContactSupportURL();

      fetch(contactSupportUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: params,
      })
        .then(response => {
          if (response?.status !== 201) {
            console.error("Failed to submit the support ticket", response);
          }
          return response.json();
        })
        .then(data => {
          if (data?.success) {
            onSubmitSuccess();
          } else {
            const errorMessage = getBackendErrorMessage(data, t);
            onSubmitError(errorMessage);
            console.error("Failed to submit the support ticket", data);
          }
          setSubmitting(false);
        })
        .catch(error => {
          onSubmitError();
          setSubmitting(false);
          console.error("Failed to submit the support ticket", error);
        });
    } catch (error: any) {
      putStatusMessage({
        message: "Failed to request PrivateLink",
        type: StatusMessageType.Error,
      });
      setSubmitting(false);
    }
  };

  return (
    <FormModal
      open
      onClose={onClose}
      classes={{ paper: classNames(styles.paper, styles.paper__large) }}
    >
      <Suspense fallback={<LoadingOverlap isLoading />}>
        <PrivateLink
          currentAccount={account.accountName}
          accounts={accounts}
          orgName={organization.organizationName}
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={submitting}
          title={t("privateLink.title")}
          submit={t("privateLink.submit")}
        />
      </Suspense>
    </FormModal>
  );
};
