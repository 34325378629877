import { useCallback, useState } from "react";

import { PrivateLinkCreate } from "./PrivateLinkCreate";

export const usePrivateLink = () => {
  const [open, setOpen] = useState<boolean>(false);
  const openPrivateLinkForm = useCallback((onOpen: () => void) => {
    onOpen();
    setOpen(true);
  }, []);

  const closePrivateLinkForm = useCallback(() => {
    setOpen(false);
  }, []);

  let privateLinkMarkup = null;

  if (open) {
    privateLinkMarkup = <PrivateLinkCreate onClose={closePrivateLinkForm} />;
  }

  return {
    openPrivateLinkForm,
    closePrivateLinkForm,
    privateLinkMarkup,
  };
};
