import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import MaterialButton, { ButtonSize, ButtonTemplate } from "components/Button";
import { FiltersContext } from "components/Datagrid/context";
import { formatNumber } from "components/Datagrid/utils/formatCell";

import styles from "./styles.module.scss";

interface Props {
  applyFilters: () => void;
  localFilters: any;
  itemsCount: number;
  showClearAll: boolean;
  showApply: boolean;
  handleClearAll: () => void;
}
export const Footer = (props: Props) => {
  const {
    applyFilters,
    localFilters,
    itemsCount,
    showApply,
    showClearAll,
    handleClearAll,
  } = props;
  const { closeFilters } = useContext(FiltersContext);
  const { include } = localFilters;
  const { t } = useTranslation();

  const countToApply = useMemo(() => {
    if (!include?.items?.length) {
      return itemsCount;
    }
    return include?.items?.reduce((acc: number, item: any) => {
      return acc + item.count;
    }, 0);
  }, [include, itemsCount]);

  return (
    <div className={styles.footer}>
      {showApply && (
        <>
          <MaterialButton
            text={t("filters.apply_all", { count: formatNumber(countToApply) })}
            disabled={false}
            onClick={applyFilters}
            size={ButtonSize.Medium}
            template={ButtonTemplate.Primary}
            dataTestid="filters-apply"
          />
          <MaterialButton
            text={t("filters.cancel")}
            onClick={closeFilters}
            template={ButtonTemplate.Secondary}
            size={ButtonSize.Medium}
            dataTestid="filters-cancel"
          />
        </>
      )}
      {showClearAll && (
        <MaterialButton
          text={t("filters.clear_all_filters")}
          disabled={false}
          onClick={handleClearAll}
          size={ButtonSize.Medium}
          template={showApply ? ButtonTemplate.Tertiary : ButtonTemplate.Danger}
          dataTestid="clear-all-filters-footer"
        />
      )}
    </div>
  );
};
