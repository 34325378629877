import classNames from "classnames";
import React from "react";

import CaretDown from "assets/icons/CaretDownv2.svg?react";
import CheckIcon from "assets/icons/CheckIcon.svg?react";

import { ChevronRight } from "components/Icons";

import styles from "./styles.module.scss";

type InnerProps = {
  itemText: string;
  testId?: string;
  onClick?: () => void;
  hasIcon?: boolean;
};

export const InnerAccordionSummary = (props: InnerProps) => {
  const { itemText, onClick, hasIcon, testId } = props;
  return (
    <div
      className={styles.accordionTextContainer}
      onClick={() => onClick?.()}
      data-testid={testId}
    >
      <span
        className={classNames(styles.accordionText, styles.innerAccordionText)}
      >
        {itemText}
      </span>
      {hasIcon && <CheckIcon className={styles.icon} />}
    </div>
  );
};

type Props = {
  itemText: string;
  testId?: string;
  onClick?: () => void;
  active: boolean;
};

export const AccordionSummaryCollapsed = (props: Props) => {
  const { itemText, onClick, testId, active } = props;
  return (
    <div
      className={styles.accordionTextContainer}
      onClick={() => onClick?.()}
      data-testid={testId}
    >
      <span
        className={classNames(styles.accordionText, {
          [styles.active]: active,
        })}
      >
        {itemText}
        {active && <span className={styles.asterisk}>*</span>}
      </span>
      <ChevronRight className={styles.icon} />
    </div>
  );
};

export const AccordionSummaryExpanded = (props: Props) => {
  const { itemText, onClick, testId, active } = props;
  return (
    <div
      className={styles.accordionTextContainer}
      onClick={() => onClick?.()}
      data-testid={testId}
    >
      <span
        className={classNames(styles.accordionText, {
          [styles.active]: active,
        })}
      >
        {itemText}
        {active && <span className={styles.asterisk}>*</span>}
      </span>
      <CaretDown className={styles.icon} />
    </div>
  );
};
