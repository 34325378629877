import { TFunction } from "i18next";

import { bulkItemTitleMap } from "./constant";

export const getOtherGroupsObjects = (
  groups: { objects: string[] }[],
  index: number
): Record<string, boolean> => {
  const otherGroupsObjects = groups.reduce<Record<string, boolean>>(
    (acc, group, groupIndex) => {
      if (index === groupIndex) {
        return acc;
      }

      for (const object of group.objects) {
        acc[object] = true;
      }
      return acc;
    },
    {}
  );
  return otherGroupsObjects;
};

export const getBulkItemTitle = (t: TFunction, value: string) => {
  return bulkItemTitleMap[value as keyof typeof bulkItemTitleMap]
    ? t(bulkItemTitleMap[value as keyof typeof bulkItemTitleMap])
    : null;
};
