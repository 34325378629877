import { Divider } from "@mui/material";
import React, { useCallback, useContext } from "react";

import CustomAccordion from "components/Accordion";
import { INCLUDE } from "components/Datagrid/Filters/actions";

import { Candidates } from "../Candidates";
import { FilterModalContext } from "../Context";
import { Search } from "../Search";
import { useCandidateSearch } from "../useCandidateSearch";
import { AccordionSummaryCollapsed } from "./AccordionSummary";

import styles from "../styles.module.scss";

const accordionItemName = "SELECTORS_CONTENT";

type Props = {
  expandedItem: string;
  setExpandedItem: (itemName: string) => void;
  handleClearAll: () => void;
  active: boolean;
};

export const SelectorsContent = (props: Props) => {
  const { expandedItem, setExpandedItem, handleClearAll, active } = props;
  const { dispatch, localFilters, items } = useContext(FilterModalContext);

  const { search, filteredItems, handleSearchChange } =
    useCandidateSearch(items);

  const handleSelectItem = useCallback(
    (selectedItem: {}) => {
      dispatch({
        type: INCLUDE,
        items,
        selectedItem,
      });
    },
    [items, dispatch]
  );

  const expanded = expandedItem === accordionItemName;
  return (
    <CustomAccordion
      expanded={expanded}
      collapsedSummary={
        <AccordionSummaryCollapsed
          itemText="Selected"
          active={active}
          onClick={() => setExpandedItem(accordionItemName)}
          testId="selected-accordion-toggle"
        />
      }
      expandedSummary={
        <Search
          onChange={handleSearchChange}
          value={search}
        />
      }
      accordionDetails={
        <>
          <Candidates
            items={filteredItems}
            onSelect={handleSelectItem}
            filters={localFilters}
            search={search}
            handleClearAll={handleClearAll}
          />
          <Divider className={styles.divider} />
        </>
      }
    />
  );
};
