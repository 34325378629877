import { useTranslation } from "react-i18next";

import { Role } from "services/rbac/types";

import { Transaction } from "../PrivilegesTransaction";
import { Modal } from "./Modal/Modal";

type Props = {
  roles: Role[];
  editRole: Role;
  onConfirm: (transaction: Transaction) => void;
  onClose: () => void;
  isLoading: boolean;
};

export const EditModal = (props: Props) => {
  const { roles, onClose, onConfirm, editRole, isLoading } = props;
  const { t } = useTranslation();
  return (
    <Modal
      title={t("roles.edit_modal.title")}
      confirmText={t("roles.edit_modal.confirm") as string}
      testId="edit-role-modal"
      onClose={onClose}
      onConfirm={onConfirm}
      role={editRole}
      isLoading={isLoading}
      roles={roles}
      disableNameChange
    />
  );
};
