import { useTranslation } from "react-i18next";

import { ExclamationIcon } from "components/Icons";
import Tooltip from "components/Tooltip";

import styles from "../styles.module.scss";

export const TypeMismatchTooltip = () => {
  const { t } = useTranslation();
  const content = (
    <div className={styles.tooltipErrorContainer}>
      <div className={styles.tooltipErrorTitle}>
        {t("wizard.map_data.errors.data_type_title")}
      </div>
      <div className={styles.tooltipErrorSubtitle}>
        {t("wizard.map_data.errors.data_type_subtitle")}
      </div>
    </div>
  );
  return (
    <Tooltip
      arrow={false}
      enterDelay={500}
      enterNextDelay={500}
      placement="top"
      title={content}
    >
      <span className={styles.icon}>
        <ExclamationIcon />
      </span>
    </Tooltip>
  );
};
