import { useReducer } from "react";

import { Role } from "services/rbac/types";

import { privilegesReducer } from "./privilegesReducer";
import { PrivilegesState, PrivilegesStateWithErrors } from "./types";
import { getPrivilegesFromRole } from "./utils";

const getInitialState = () => {
  return {
    engine: [{ toAssign: [], toDeny: [], objects: [] }],
    database: [{ toAssign: [], toDeny: [], objects: [] }],
    account: [{ toAssign: [], toDeny: [], objects: [] }],
    view: [{ toAssign: [], toDeny: [], objects: [] }],
    schema: [{ toAssign: [], toDeny: [], objects: [] }],
    table: [{ toAssign: [], toDeny: [], objects: [] }],
    permissionErrors: {
      table: [],
      view: [],
      schema: [],
    },
  };
};

type Type = "account" | "engine" | "database" | "view" | "schema" | "table";

export const usePrivileges = (initialData: PrivilegesState | null) => {
  const [state, dispatch] = useReducer(
    privilegesReducer,
    (initialData || getInitialState()) as PrivilegesStateWithErrors
  );

  const getDiff = (role: Role) => {
    const initialState = getPrivilegesFromRole(role);
    const toAssign: { type: string; resource: string; actions: string[] }[] =
      [];
    const toRevoke: { type: string; resource: string; actions: string[] }[] =
      [];

    const diffResource = (type: Type, databaseLevel: boolean = false) => {
      const nextGroups = state[type];
      const previousGroups = initialState[type];

      const groupByResource = (
        groups: { toAssign: string[]; toDeny: string[]; objects: any[] }[]
      ) => {
        return groups.reduce<
          Record<string, { toAssign: string[]; resource: any }>
        >((acc, group) => {
          const { objects, toAssign } = group;
          if (!toAssign.length) {
            return acc;
          }
          for (const resource of objects) {
            const key: string = databaseLevel
              ? resource.catalogName + "/" + resource.name
              : resource;
            acc[key] = { toAssign, resource };
          }
          return acc;
        }, {});
      };

      const previousPrivilegesByResource = groupByResource(previousGroups);
      const nextPrivilegesByResource = groupByResource(nextGroups);

      const resources = Object.keys({
        ...previousPrivilegesByResource,
        ...nextPrivilegesByResource,
      });

      for (const key of resources) {
        if (
          previousPrivilegesByResource[key] &&
          !nextPrivilegesByResource[key] &&
          previousPrivilegesByResource[key].toAssign.length
        ) {
          toRevoke.push({
            type,
            resource: previousPrivilegesByResource[key].resource,
            actions: previousPrivilegesByResource[key].toAssign,
          });
        }

        if (
          !previousPrivilegesByResource[key] &&
          nextPrivilegesByResource[key] &&
          nextPrivilegesByResource[key].toAssign.length
        ) {
          toAssign.push({
            type,
            resource: nextPrivilegesByResource[key].resource,
            actions: nextPrivilegesByResource[key].toAssign,
          });
        }

        if (
          previousPrivilegesByResource[key] &&
          nextPrivilegesByResource[key]
        ) {
          const oldPrivileges =
            previousPrivilegesByResource[key].toAssign || [];
          const newPrivileges = nextPrivilegesByResource[key].toAssign || [];

          const resource = nextPrivilegesByResource[key].resource;

          const keys: string[] = Array.from(
            new Set([...oldPrivileges, ...newPrivileges])
          );

          const toAssignActions: string[] = [];
          const toRevokeActions: string[] = [];

          for (const privilege of keys) {
            if (
              oldPrivileges.includes(privilege) &&
              !newPrivileges.includes(privilege)
            ) {
              toRevokeActions.push(privilege);
            }
            if (
              !oldPrivileges.includes(privilege) &&
              newPrivileges.includes(privilege)
            ) {
              toAssignActions.push(privilege);
            }
          }

          if (toAssignActions.length) {
            toAssign.push({
              type,
              resource,
              actions: toAssignActions,
            });
          }
          if (toRevokeActions.length) {
            toRevoke.push({
              type,
              resource,
              actions: toRevokeActions,
            });
          }
        }
      }
    };

    diffResource("account");
    diffResource("engine");
    diffResource("database");
    diffResource("schema", true);
    diffResource("table", true);
    diffResource("view", true);

    return {
      toAssign,
      toRevoke,
    };
  };

  return {
    state,
    dispatch,
    getDiff,
  };
};
