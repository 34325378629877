import { Popover } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FIREBOLT_DOCS_URL,
  FIREBOLT_RELEASE_NOTES_URL_NEW,
  FIREBOLT_STATUS_PAGE_URL,
} from "utils/constants";
import { clickOnSpaceOrEnterPress } from "utils/keyboardUtils";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { SupportAccessAgentType } from "services/api/supportAccess";
import { ReactQueryKeysAccount } from "services/queryKeys";

import HelpIcon from "assets/icons/Help.svg?react";

import { useContactSupport } from "components/ContactSupport/useContactSupport";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { usePrivateLink } from "components/PrivateLink/usePrivateLink";
import Tooltip from "components/Tooltip";

import {
  AQA_CONTACT_SUPPORT,
  AQA_DOCUMENTATION_USER,
  AQA_FIREBOLT_STATUS_PAGE,
  AQA_HELP_ICON,
  AQA_PRIVATE_LINK,
  AQA_RELEASE_NOTES,
} from "../AqaIds";

import sidebarStyles from "../MainSidebar.module.scss";
import styles from "../sidebarItemWithDropdown.module.scss";
import helpBarStyles from "./HelpBar.module.scss";

type Props = {
  openSupportAgentAccess?: (onOpen: () => void) => void;
  hasAdminRole?: boolean;
};

const SupportAgentMenuItem = (props: {
  openSupportAgentAccess: (
    onOpen: () => void,
    agent?: SupportAccessAgentType
  ) => void;
  handleClose: () => void;
}) => {
  const { openSupportAgentAccess, handleClose } = props;
  const queryClient = useQueryClient();
  const agent = queryClient.getQueryData<SupportAccessAgentType | undefined>([
    ReactQueryKeysAccount.supportAccessShort,
  ]);

  return (
    <ContextMenuItem
      text="Firebolt Support Access"
      testId="support_access"
      onClick={() => openSupportAgentAccess(handleClose, agent)}
    />
  );
};

const HelpBar: React.FunctionComponent<Props> = props => {
  const { hasAdminRole, openSupportAgentAccess } = props;
  const { t } = useTranslation();
  const flags = useAllFlags();
  const { isLoggedIn } = useLoggedInUser();
  const [anchorEl, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent) => {
    setAnchorElement(event.currentTarget as HTMLElement);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const { contactSupportMarkup, openForm } = useContactSupport({
    onOpen: handleClose,
  });

  const { openPrivateLinkForm, privateLinkMarkup } = usePrivateLink();

  const showContactItem =
    flags?.[FeatureFlag.FireboltAppEnableContactSupportFormLoggedIn] &&
    isLoggedIn;

  const showPrivateLink =
    flags?.[FeatureFlag.FireboltAppEnablePrivateLink] && isLoggedIn;

  const documentationItems = [
    <ContextMenuItem
      text={t("help_bar.status_page_item")}
      key="statusPage"
      testId={AQA_FIREBOLT_STATUS_PAGE}
      to={FIREBOLT_STATUS_PAGE_URL}
      onClick={handleClose}
    />,
    <ContextMenuItem
      text={t("help_bar.release_notes_item")}
      key="release_notes"
      testId={AQA_RELEASE_NOTES}
      to={FIREBOLT_RELEASE_NOTES_URL_NEW}
      onClick={handleClose}
    />,
    showContactItem ? (
      <ContextMenuItem
        text={t("help_bar.contact_support_item")}
        key="contact_support"
        testId={AQA_CONTACT_SUPPORT}
        onClick={openForm}
      />
    ) : null,
    hasAdminRole && openSupportAgentAccess ? (
      <SupportAgentMenuItem
        key="support_access"
        openSupportAgentAccess={openSupportAgentAccess}
        handleClose={handleClose}
      />
    ) : null,
    showPrivateLink ? (
      <ContextMenuItem
        text={t("help_bar.request_private_link_item")}
        key="request_private_link"
        testId={AQA_PRIVATE_LINK}
        onClick={() => openPrivateLinkForm(handleClose)}
      />
    ) : null,
    <ContextMenuItem
      text={t("help_bar.documentation_item")}
      key="documentation"
      testId={AQA_DOCUMENTATION_USER}
      to={FIREBOLT_DOCS_URL}
      onClick={handleClose}
    />,
  ];

  return (
    <>
      <div>
        <Tooltip
          title={t("helpTooltipTitle")}
          placement="right"
          classes={{
            popper: classNames(
              sidebarStyles.menuItemTooltip,
              sidebarStyles.forced
            ),
          }}
          disableFocusListener
        >
          <div
            className={classNames(styles.root, styles["root--noBorderBottom"])}
            onClick={handleClick}
            tabIndex={0}
            onKeyUp={clickOnSpaceOrEnterPress}
          >
            <div className={styles.container}>
              <span
                className={styles.helpIcon}
                data-testid={AQA_HELP_ICON}
              >
                <HelpIcon />
              </span>
            </div>
          </div>
        </Tooltip>
      </div>
      {!!anchorEl && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            paper: helpBarStyles.paper,
          }}
        >
          <ContextMenu>{documentationItems}</ContextMenu>
        </Popover>
      )}
      {contactSupportMarkup}
      {privateLinkMarkup}
    </>
  );
};

export default HelpBar;
