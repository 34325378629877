import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { GroupInner } from "./GroupInner";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { ALL_DATABASES } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";
import { getOtherGroupsObjects } from "./utils";

import styles from "./styles.module.scss";

const privileges = ["MODIFY", "USAGE", "USAGE ANY SCHEMA", "VACUUM ANY"];

type Props = {
  index: number;
  resource: string;
};

export const DatabasesGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const databases = useDatabasesNames();
  const { t } = useTranslation();
  const menu = useMenu();

  const groupState = privilegesState.database[index];

  const otherGroupsObjects = getOtherGroupsObjects(
    privilegesState.database,
    index
  );

  const { objects } = groupState;

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  const {
    onGrantPrivileges,
    onRevokePrivileges,
    onSelectResourceObjects,
    deleteGroup,
  } = usePrivilegesHandlers({ index, resource });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [ALL_DATABASES],
        index,
      });
    }
  }, [dependencies]);

  const menuItems = [
    [
      ...(objects.includes(ALL_DATABASES)
        ? []
        : [
            {
              key: "add_remaining",
              text: t("roles_wizard.privileges.menu.add_remaining_databases"),
              action: () => {
                privilegesDispatch({
                  type: ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
                  resource,
                  allObjects: databases.map(({ catalogName }) => catalogName),
                });
              },
              testId: "roles-wizard-add-remaining-databases",
            },
          ]),
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [ALL_DATABASES],
          });
        },
        testId: "roles-wizard-add-databases-group",
      },
    ],

    ...(privilegesState.database.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-databases-group",
            },
          ],
        ]
      : []),
  ];

  const items = [
    <ContextMenuItem
      value={ALL_DATABASES}
      key={ALL_DATABASES}
      checked={objects.includes(ALL_DATABASES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_databases")}
      direction="left"
      bulkItem
      skipFilter
      onClick={() =>
        onSelectResourceObjects(
          objects.includes(ALL_DATABASES) ? [] : [ALL_DATABASES]
        )
      }
    />,
    <ContextMenuDivider key="divider" />,
    ...databases.map(database => {
      const { catalogName } = database;
      const disabled = otherGroupsObjects[catalogName];
      const disabledTooltip = disabled
        ? t("roles_wizard.privileges.disabled_database_tooltip", {
            database: catalogName,
          })
        : undefined;
      return (
        <ContextMenuItem
          value={catalogName}
          key={catalogName}
          checked={objects.includes(catalogName)}
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          checkedIconPlaceholder={true}
          text={catalogName}
          direction="left"
          onClick={() => {
            objects.includes(catalogName)
              ? onSelectResourceObjects(
                  objects
                    .filter(obj => obj !== catalogName)
                    .filter(obj => obj !== ALL_DATABASES)
                )
              : onSelectResourceObjects(
                  [...objects, catalogName].filter(obj => obj !== ALL_DATABASES)
                );
          }}
        />
      );
    }),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase().replace("usage", "use")),
    };
  });

  return (
    <div className={styles.group}>
      <GroupInner
        objects={objects}
        items={items}
        resource={resource}
        index={index}
        searchPlaceholder={t("roles_wizard.privileges.search_database")}
      />
      <GrantPrivilegesSelector
        onChange={onGrantPrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <RevokePrivilegeSelector
        onChange={onRevokePrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />
      <Menu
        items={menuItems}
        menu={menu}
        testId={`roles-wizard-${resource}-${index}-menu`}
        alwaysVisible
      />
    </div>
  );
};
